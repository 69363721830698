<template>
  <div class="app-container">
    <div class="head-container">
      <quick-select v-model="query.entId" filterable clearable url="api/purchase/seller" placeholder="选择供应商" value-field="belongTo" display-field="belongToName" @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-date-picker v-model="query.dateRange" type="daterange" class="filter-item" unlink-panels range-separator="至" start-placeholder="返利日期起始" end-placeholder="返利日期截止" :picker-options="searchDateRangeOptions" value-format="timestamp" :default-time="['00:00:00', '23:59:59']" @change="toQuery" />
      <quick-select v-model="query.projectId" filterable clearable url="api/payProject/0" placeholder="记账类型" @change="toQuery" class="filter-item" style="width: 180px;" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="code" label="记账单号" width="130" fixed />
      <el-table-column prop="erpCode" label="ERP单号" width="130" fixed />
      <el-table-column prop="projectName" label="记账类型" width="120" />
      <el-table-column prop="payMoney" label="金额" align="right" width="120" :formatter="$price" />
      <el-table-column label="登记时间" width="150" :formatter="r => {return new Date(r.payTime).format();}" />
      <el-table-column prop="info" label="备注" show-overflow-tooltip min-width="200" />
      <el-table-column label="状态" width="80" align="center" fixed="right">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="detail(scope.row, false)">查看</el-button>
          <el-button size="mini" type="text" @click="detail(scope.row, true)" v-if="scope.row.status === 0 && scope.row.inOutType === 'in'">支付</el-button>
        </div>
      </el-table-column>
    </el-table>
    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <e-form ref="form" />
  </div>
</template>

<script>
import initData from "@/mixins/initData";
import eForm from "./form";

export default {
  components: { eForm },
  mixins: [initData],
  data() {
    return {
      searchDateRangeOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      paymentMethods: ["在线支付", "线下支付"],
      status: [
        {
          type: "info",
          label: "待付款",
        },
        {
          type: "success",
          label: "已收款",
        },
        {
          type: "danger",
          label: "已拒收",
        },
        {
          type: "primary",
          label: "已支付",
        },
      ],
      query: {
        entId: null,
        dateRange: null,
        projectId: null,
      },
    };
  },
  methods: {
    beforeInit() {
      this.url = "api/payForm/buyer";
      let query = JSON.parse(JSON.stringify(this.query));
      if (query.dateRange && query.dateRange.length === 2) {
        query.begTime = query.dateRange[0];
        query.endTime = query.dateRange[1];
      }
      query.inOutType = "in";
      query.relationFormType = 1;
      delete query.dateRange;
      query.sort = "createAt,asc";
      this.params = query;
      return true;
    },
    add(inout, formType) {
      this.$refs.form &&
        this.$refs.form.resetForm(null, false, {
          inOutType: inout,
          relationFormType: formType,
        });
    },
    detail(row, payable = false) {
      this.$refs.form &&
        this.$refs.form.resetForm(JSON.parse(JSON.stringify(row)), payable);
    },
  },
  mounted() {
    this.init();
  },
};
</script>